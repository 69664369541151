<template>	
	  <div class="offer bg-primary">
	  	<Masthead :height="300" :noLetters="true" />
	    <div class="container offer-container">
        <div class="row">
          <div class="col certificate" v-for="(item, index) in imgItems" :key="index">
            <img class="img-fluid" :src="item.thumbnail" @click="zoomModal(item.img)" style="cursor:pointer" data-aos="flip-up"/>
          </div>         
        </div>
        <ZoomModal :imgSrc="imgSrc" :isActive="true" id="zoomModal"/>
      </div> 
	  </div>
</template>
<script>
import Masthead from '@/components/Masthead.vue';
import ZoomModal from '@/components/ZoomModal.vue';
export default {
  name: 'Certificates',
  components: {
     Masthead,ZoomModal
  },
  data: function () {
    return {
      imgSrc: '',
      imgItems: [
        {
          thumbnail: require('@/assets/img/Certificates/c0.min.png'),
          img: require('@/assets/img/Certificates/c0.jpg'),
        },
        {
          thumbnail: require('@/assets/img/Certificates/c1.min.png'),
          img: require('@/assets/img/Certificates/c1.jpg'),
        },
        {
          thumbnail: require('@/assets/img/Certificates/c2.min.png'),
          img: require('@/assets/img/Certificates/c2.jpg'),
        },
        {
          thumbnail: require('@/assets/img/Certificates/c3.min.png'),
          img: require('@/assets/img/Certificates/c3.jpg'),
        },
        {
          thumbnail: require('@/assets/img/Certificates/c04.min.jpg'),
          img: require('@/assets/img/Certificates/c04.jpg'),
        },
        {
          thumbnail: require('@/assets/img/Certificates/c05.min.jpg'),
          img: require('@/assets/img/Certificates/c05.jpg'),
        }
      ]
    }
  },
  metaInfo() {
      return{
        title: this._i18n.t('StalmatCertificates'),
        meta: [
          { 
            name: 'description', 
            content: 'STALMAT - Firma STALMAT powstała w 2005 roku. Głównym przedmiotem naszej działalności jest produkcja najwyższej jakości śrubowych elementów złącznych wykonanych według norm oraz dostarczonej dokumentacji i rysunków' 
          },
          { 
            name: 'keywords', 
            content: 'stalmat,śruby,śruby dwustronne,nakrętki,podkładki,sworznie' 
          },
          { 
            name: 'author', 
            content: 'https://www.itfrog.pl' 
          },
          { 
            name: 'robots', 
            content: 'index,follow' 
          }
        ]
      }
    },
  created: function(){
  },
  methods: {
    zoomModal(item) { 
      this.imgSrc = item;
      $('#zoomModal').modal('show');
    }
  }
}
</script>