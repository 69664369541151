<template>
  	<div class="modal fade" role="dialog" tabindex="-1" style="cursor:pointer">
	  <div class="modal-dialog modal-dialog-centered zoomModal" role="document">
	    <div class="modal-content">
	      <div class="modal-body mb-0 p-0 zoom-body">
	      	<button type="button" class="close zoom-close" aria-label="Close" data-dismiss="modal">
			  <span aria-hidden="true">&times;</span>
			</button>
	        <img v-bind:src="imgSrc" alt="" style="width:100%;">
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
export default {
  name: 'ZoomModal',
  props: ['imgSrc'],
}
</script>
